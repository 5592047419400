<template>
  <div>
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <div class="content">
      <crud-cpf-dialog
        :dialog="dialog"
        :titulo="'Unidade de Negócio'"
        :profile="'unidadeNegocio'"
        :store="'unidadesNegocio'"
        :validar="'CNPJ'"
        @close-dialog="setFields"
      />
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <crud-cadastro :loading="loading">
          <v-card
            outlined
            class="px-6 pt-4"
            style="margin-top: 14px; border-radius: 16px"
          >
            <v-card-title
              class="pa-0"
              style="text-shadow: grey 0.1em 0.1em 0.2em"
            >
              Dados Principais
            </v-card-title>
            <v-row>
              <v-col
                cols="2"
                md="2"
              >
                <v-text-field
                  v-model="cod_centro"
                  v-mask="maskNumber"
                  :rules="[rules.required]"
                  dense
                  outlined
                  rounded
                  autocomplete="off"
                  label="Código de centro*"
                  required
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="4"
                md="4"
              >
                <v-autocomplete
                  v-model="gestor_id"
                  :items="gestores"
                  item-text="nome"
                  item-value="id"
                  class="d-flex justify-space-between"
                  label="Gestor"
                  clearable
                  dense
                  outlined
                  rounded
                  autocomplete="nope"
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-container
                      class="rounded-lg mx-n2"
                      fluid
                    >
                      <v-row
                        dense
                        justify="space-between"
                      >
                        <div>{{ data.item.nome }}</div>
                        <div>CPF {{ data.item.cpf }}</div>
                      </v-row>
                    </v-container>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="pa-0">
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="razao_social"
                  dense
                  outlined
                  rounded
                  autocomplete="off"
                  label="Razão Social*"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="nome_fantasia"
                  dense
                  outlined
                  rounded
                  autocomplete="off"
                  class="no-auto-complete"
                  :rules="[rules.required]"
                  label="Filial*"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="cnpj"
                  v-mask="maskCnpj"
                  dense
                  outlined
                  rounded
                  disabled
                  autocomplete="off"
                  :rules="[rules.cnpj, rules.required]"
                  label="CNPJ*"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  rounded
                  autocomplete="new-email"
                  :rules="[rules.required, rules.email]"
                  label="E-mail - Principal*"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email_adm_clube"
                  dense
                  outlined
                  rounded
                  autocomplete="new-email"
                  :rules="[rules.required, rules.email]"
                  label="E-mail - Secundário*"
                />
              </v-col>
            </v-row>
            <crud-contato
              :telefones="telefones"
              :input-outlined="true"
            />
          </v-card>

          <crud-localizacao class="mt-3" />

          <v-row
            class="pa-3"
            justify="center"
            justify-sm="end"
          >
            <v-btn
              class="mr-3"
              color="error"
              rounded
              @click="
                $router.push({
                  path: `/${$user.get().role}/unidadesnegocio`,
                })
              "
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              :disabled="desabilitaBotao"
              rounded
              @click="submitForm"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-row>
        </crud-cadastro>
      </v-form>
    </div>
  </div>
</template>
<script>
  import rules from '../../utils/formRules'
  import UnidadeNegocioFormStore, {
    BOOTSTRAP_PROFILE,
    SUBMIT,
  } from '@/store/modules/forms/unidadeNegocio'
  import { mapState, mapActions } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  export default {
    data () {
      return {
        tab: 0,
        id: null,
        valid: false,
        telefones: [
          {
            tipo: 'Comercial',
            format: 'com',
            title: 'Telefone 1',
            required: true,
          },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        rules: rules,
        maskCnpj: '##.###.###/####-##',
        maskNumber: '###############',
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/unidadeNegocio', [
        'gestores',
        'form',
        'isEditing',
        'loading',
      ]),
      ...mapFields('form', [
        'data.codigo',
        'data.unidades',
        'data.nome_fantasia',
        'data.cod_centro',
        'data.email_adm_clube',
        'data.regional_id',
        'data.cnpj',
        'data.gestor_id',
        'data.razao_social',
        'data.email',
      ]),
      ...mapFields('form/unidadeNegocio', ['dialog']),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.unidadeNegocio) {
        this.$store.registerModule(
          ['form', 'unidadeNegocio'],
          UnidadeNegocioFormStore,
        )
      }
      const id = this.$route.query.id
      this.id = id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/unidadeNegocio', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/unidadesnegocio/cadastro/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message:
              'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
